/* eslint-disable max-depth */
/* eslint-disable max-len */

/*
  This function should be used like a class.
  let RedirectManager = require("./RedirectManager");
  const redirectManager = RedirectManager(window.ENVIRONMENT_CONFIG, window.localStorage);

  redirectManager.save(queryParams);
  etc...
*/
function RedirectManager (ENVIRONMENT_CONFIG, localStorage) {
  // Fields
  let environmentConfig = ENVIRONMENT_CONFIG;
  let useFromUri = false;

  const apps = {
    myf5: { name: "myf5", appPath: environmentConfig.appPathMyF5 },
    cleo: { name: "cleo", appPath: environmentConfig.appPathCleo },
    learnf5: { name: "learnf5", appPath: environmentConfig.appPathLearnF5 },
    partnercentral: { name: "partnercentral", appPath: environmentConfig.appPathPartnerCentral },
    cloudservices: { name: "cloudservices", redirectUri: environmentConfig.redirectUriCloudServices },
    ihealth: { name: "ihealth", redirectUri: environmentConfig.redirectUriIHealth },
    ihealth2: { name: "ihealth2", redirectUri: environmentConfig.redirectUriIHealth2 },
    f5labs: { name: "f5labs", redirectUri: environmentConfig.redirectUriF5Labs },
    udf: { name: "udf", redirectUri: environmentConfig.redirectUriUdf },
    community: { name: "community", redirectUri: environmentConfig.redirectUriCommunity },
    myprofile: { name: 'myprofile', redirectUri: environmentConfig.redirectUriMyProfile },
    advocacy: { name: "advocacy", appPath: environmentConfig.appPathAdvocacy },
  };

  // Private functions
  
  let buildSamlUrlFromEnv = function () {
    if (!environmentConfig.oktaBaseUrl || !environmentConfig.appPath) {
      return undefined;
    }

    return `${environmentConfig.oktaBaseUrl}/${environmentConfig.appPath}/sso/saml`;
  };

  let buildOidcUrlFromEnv = function () {
    if (!environmentConfig.redirectUri) {
      return undefined;
    }

    return environmentConfig.redirectUri;
  };
  
  let buildSamlUrlFromLocalStorage = function (appPath) {
    if (!environmentConfig.oktaBaseUrl || !appPath) {
      return undefined;
    }

    return `${environmentConfig.oktaBaseUrl}/${appPath}/sso/saml`;
  };
  
  let getRelayState = function (queryParams) {
    let regexResults = new RegExp(/[\?&]relaystate=([^&#]*)/, 'i').exec(queryParams);
    if (regexResults != null) {
      let relayStateUrl = regexResults[1];
      relayStateUrl = decodeURIComponent(relayStateUrl);
      useFromUri = false;
      return relayStateUrl;
    } else {
      return undefined;
    }
  };

  let getStateToken = function (queryParams) {
    let regexResults = new RegExp(/[\?&]statetoken=([^&#]*)/, 'i').exec(queryParams);
    if (regexResults != null) {
      let stateToken = regexResults[1];
      stateToken = decodeURIComponent(stateToken);
      useFromUri = false;
      return atob(stateToken);
    } else {
      return undefined;
    }
  };

  let getidpDiscoveryRequestContext = function (queryParams) {
    let regexResults = new RegExp(/[\?&]idpdrc=([^&#]*)/, 'i').exec(queryParams);
    if (regexResults != null) {
      let url = regexResults[1];
      url = decodeURIComponent(url);
      useFromUri = false;
      return atob(url);
    } else {
      return undefined;
    }
  };

  let getAppName = function (queryParams) {
    let regexResults = new RegExp(/[\?&]appName=([^&#]*)/, 'i').exec(queryParams);
    if (regexResults != null) {
      let appName = regexResults[1];
      appName = decodeURIComponent(appName);
      useFromUri = false;
      return appName;
    } else {
      return undefined;
    }
  };
  
  let getReturnUrl = function (queryParams) {
    let regexResults = new RegExp(/[\?&]returnurl=([^&#]*)/, 'i').exec(queryParams);
    if (regexResults != null) {
      let retUrl = regexResults[1];
      retUrl = removeHost(retUrl);
      retUrl = decodeURIComponent(retUrl);
      useFromUri = false;
      return retUrl;
    } else {
      return undefined;
    }
  };

  // This query parameter is set by Okta when you select the 'Use a custom login page for this application' setting.
  // The value of fromURI will be a full Okta URL (e.g. https://identity.account.f5.com/{appPath}/sso/saml) and it will be URL encoded.
  // Once the user logs in, you navigate to that (decoded) URL to finish the login flow.
  // The RelayState is managed for you by Okta when you use this method.
  let getFromUri = function (queryParams) {
    let regexResults = new RegExp(/[\?&]fromURI=([^&#]*)/, 'i').exec(queryParams);
    if (regexResults != null) {
      let fromUri = regexResults[1];
      fromUri = decodeURIComponent(fromUri);
      useFromUri = true;
      return fromUri;
    } else {
      return undefined;
    }
  };
  
  let removeHost = function (urlStr) {
    let regexResults = new RegExp(/http([^&#].*?)\.(.*?)(.com)/, 'i').exec(urlStr);
    if (regexResults != null) {
      let hostPart = regexResults[0];
      let splitResults = urlStr.split(hostPart);
      if (splitResults.length > 1) {
        return splitResults[1];
      }
    }
    return urlStr;
  };

  // Public functions

  /**
   * This is the first function that runs after instantiating the RedirectManager
   * object. It adds the appBaseUrl and relayState to the window's local storage
   * (window.localStorage)
   * 
   * @param {Object} queryParams URL's query params including leading "?"
   */
  let save = function (queryParams) {
    let relayStateUrl = getRelayState(queryParams);
    let appName = getAppName(queryParams);

    if (!relayStateUrl) {
      relayStateUrl = getReturnUrl(queryParams);
    }

    if (!relayStateUrl) {
      relayStateUrl = getFromUri(queryParams);
    }

    if (environmentConfig.spaRootUrl) {
      let localStorageValue = localStorage.getItem("relayState");
      if (localStorageValue) {
        localStorageValue = JSON.parse(localStorageValue);
        if (!localStorageValue.relayState) {
          localStorage.setItem('relayState', JSON.stringify({ appBaseUrl: environmentConfig.spaRootUrl, relayState: relayStateUrl || "" }));
        }
      } else {
        localStorage.setItem('relayState', JSON.stringify({ appBaseUrl: environmentConfig.spaRootUrl, relayState: relayStateUrl || "" }));
      }
    }else if(window.location.href.includes('/profile')){
      localStorage.setItem('relayState', JSON.stringify({ appBaseUrl: appName || "", relayState: relayStateUrl || "" }));
    }
  };

  let setAppName = function(appName){
    appName = appName ? appName.toLowerCase() : "";
    let redirectInfo = load() || {};
    if(appName in apps){
      redirectInfo.appBaseUrl = appName;
      redirectInfo.relayState = redirectInfo.relayState || "";
      localStorage.setItem('relayState', JSON.stringify(redirectInfo));
    }
  }

  let load = function () {
    let localStorageValue = localStorage.getItem("relayState");
    if (localStorageValue) {
      return JSON.parse(localStorageValue);
    } else {
      return undefined;
    }
  };

  let remove = function () {
    localStorage.removeItem("relayState");
  };

  let getAppUrl = function (redirectInfo) {
    let target = "/";
    if (redirectInfo && redirectInfo.appBaseUrl && (redirectInfo.appBaseUrl in apps)) {
      target += apps[redirectInfo.appBaseUrl].name;
    }
    return target;
  };

  let getIdpRedirectUrl = function (queryParams) {
    let statetoken = getStateToken(queryParams);
    if (statetoken) {
      let idpDiscoveryRequestContext=getidpDiscoveryRequestContext(queryParams)
      if(idpDiscoveryRequestContext)
        return idpDiscoveryRequestContext;
    }
    let url =
      ENVIRONMENT_CONFIG.redirectUri !== undefined
        ? ENVIRONMENT_CONFIG.redirectUri
        : ENVIRONMENT_CONFIG.appPath !== undefined
        ? `${ENVIRONMENT_CONFIG.oktaBaseUrl}/${ENVIRONMENT_CONFIG.appPath}/sso/saml`
        : null;
    if (url === null) {
      return window.location.href;
    }
  
    let relayState = getRelayState(queryParams);
    if (!relayState) {
      relayState = getReturnUrl(queryParams);
    }
    if (!relayState) {
      relayState = getFromUri(queryParams);
    }
  
    if (ENVIRONMENT_CONFIG.spaRootUrl === 'f5labs') {
      url = `${window.location.origin}/${ENVIRONMENT_CONFIG.spaRootUrl}`;
    }
    if (relayState) {
      relayState = encodeURIComponent(relayState);
      if (ENVIRONMENT_CONFIG.spaRootUrl === 'community') {
        url += `?referer=${relayState}`;
      } else if (ENVIRONMENT_CONFIG.spaRootUrl !== 'cleo') {
        url += `?RelayState=${relayState}`;
      }
    }
  
    return url;
  };

  let getSamlUrlFromEnv = function (redirectInfo) {
    let target = buildSamlUrlFromEnv();
    if (!target) {
      return undefined;
    }

    if (redirectInfo && redirectInfo.relayState && redirectInfo.appBaseUrl && redirectInfo.appBaseUrl === environmentConfig.spaRootUrl) {
      let relayStateUrl = redirectInfo.relayState;
      if (useFromUri === true) {
        target = relayStateUrl;
        useFromUri = false;
      } else {
        relayStateUrl = encodeURIComponent(relayStateUrl);
        target += `?RelayState=${relayStateUrl}`;
      }
    }
    return target;
  };



  let getOidcUrlFromEnv = function (redirectInfo) {
    let target = buildOidcUrlFromEnv();
    if (!target) {
      return undefined;
    }
    if (redirectInfo && redirectInfo.relayState && redirectInfo.appBaseUrl && redirectInfo.appBaseUrl === environmentConfig.spaRootUrl) {
      let relayStateUrl = redirectInfo.relayState;
      relayStateUrl = encodeURIComponent(relayStateUrl);
      if(redirectInfo.appBaseUrl === 'community'){
        target += `?referer=${relayStateUrl}`;
      } else {
        target += `?RelayState=${relayStateUrl}`;
      }
    }
    return target;
  };

  let getSamlUrlFromLocalStorage = function (redirectInfo) {
    let target = undefined;
    if (redirectInfo && redirectInfo.appBaseUrl && (redirectInfo.appBaseUrl in apps)) {
      target = buildSamlUrlFromLocalStorage(apps[redirectInfo.appBaseUrl].appPath);
      if (!target) {
        return undefined;
      }

      if (redirectInfo.relayState) {
        let relayStateUrl = redirectInfo.relayState;
        if (useFromUri === true) {
          target = relayStateUrl;
          useFromUri = false;
        } else {
          relayStateUrl = encodeURIComponent(relayStateUrl);
          target += `?RelayState=${relayStateUrl}`;
        }
      }
    }
    return target;
  };

  let getOidcUrlFromLocalStorage = function (redirectInfo) {
    let target = undefined;
    if (redirectInfo && redirectInfo.appBaseUrl && (redirectInfo.appBaseUrl in apps)) {
      target = apps[redirectInfo.appBaseUrl].redirectUri;
      if (!target) {
        return undefined;
      }
      if (redirectInfo.relayState) {
        let relayStateUrl = redirectInfo.relayState;
        relayStateUrl = encodeURIComponent(relayStateUrl);
        if(redirectInfo.appBaseUrl === 'community'){
          target += `?referer=${relayStateUrl}`;
        } else {
          target += `?RelayState=${relayStateUrl}`;
        }
      }
    }
    return target;
  };

  let checkUseFromUri = function () {
    return useFromUri;
  }

  // Expose public functions
  return {
    save: save,
    load: load,
    remove: remove,
    getAppUrl: getAppUrl,
    getIdpRedirectUrl: getIdpRedirectUrl,
    getSamlUrlFromEnv: getSamlUrlFromEnv,
    getOidcUrlFromEnv: getOidcUrlFromEnv,
    getSamlUrlFromLocalStorage: getSamlUrlFromLocalStorage,
    getOidcUrlFromLocalStorage: getOidcUrlFromLocalStorage,
    setAppName: setAppName,
    checkUseFromUri: checkUseFromUri
  };
};

module.exports = RedirectManager;