import React from 'react';
import { widgetsInfo } from '../../helpers/widgetsInfo';
import { Helmet } from 'react-helmet';
import Header from '../widget/Header';
import Footer from '../widget/Footer';

const Error = ({ midsNewUIFeatureToggle }) => {
  return (
    <div className='f5-error-ui'>
      <h1>Well this is embarrassing.</h1>
      <p>
        We apologize for the inconvenience, it seems like our page has decided
        to play hooky today. We promise to give it a stern talking to and get
        it back up and running ASAP!
      </p>
      <span className='error-computer-icon'></span>
    </div>
  );
};

export default Error;
